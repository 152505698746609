import React from "react";
import StoryPage from "../../components/story-page";
/** @jsx jsx */
import { jsx } from "theme-ui";

const ThisPage = ({ data, location }) => {
  const imgUrl = "/images/people/dieter-j-kalkowski.jpg";
  const fullName = "Dieter J. Kalkowski";
  const birthDate = "April 18, 1934";
  const deathDate = "November 24, 2020";
  const memorialDate = "Saturday December 12th, 2020";
  const memorialTime = "10am PST / 1pm EST / 7pm CET";
  const recordingEmbedUrl =
    "https://storage.googleapis.com/afterword-static/recordings/dean-kalkowski-480.mp4";

  return (
    <StoryPage
      location={location}
      imgUrl={imgUrl}
      fullName={fullName}
      customColumnSpacing="1fr 3fr"
      birthDate={birthDate}
      deathDate={deathDate}
      memorialDate={memorialDate}
      memorialTime={memorialTime}
      recordingEmbedUrl={recordingEmbedUrl}
    ></StoryPage>
  );
};

export default ThisPage;
